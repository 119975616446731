@import '../App.scss';

.circles {
  height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 5s ease-in-out;

  .circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid white;
    position: absolute;
  }

  .circles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: circlesAnimation 24s infinite;

    @keyframes circlesAnimation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @for $j from 1 through 6 {
      &:nth-child(#{$j}) {
        animation-delay: 1s * $j;
        .circle {
          border-color: rgba(white, 1 - 0.1*$j);
        }
      }
    }

    .innerCircles {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      .innerCircle {
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transform: rotate($i * 60deg) translate(0, -100px);
            animation: innerCircleAnimation#{$i} 6s infinite linear;
          }
        }

        @for $i from 1 through 6 {
          @keyframes innerCircleAnimation#{$i} {
            0% {
              transform: rotate($i * 60deg) translate(0, -100px);
            }

            50% {
              transform: rotate($i * 60deg + 180deg) translate(0, -100px);
            }

            100% {
              transform: rotate($i * 60deg + 360deg) translate(0, -100px);
            }
          }
        }
      }
    }

    .middleCircles {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      .middleCircle {
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transform: rotate($i * 60deg + 30deg) translate(0, -172px);
            animation: middleCircleAnimation#{$i} 9s infinite linear;
          }
        }

        @for $i from 1 through 6 {
          @keyframes middleCircleAnimation#{$i} {
            0% {
              transform: rotate($i * 60deg + 30deg) translate(0, -172px);
            }

            50% {
              transform: rotate($i * 60deg + 210deg) translate(0, -172px);
            }

            100% {
              transform: rotate($i * 60deg + 390deg) translate(0, -172px);
            }
          }
        }
      }
    }

    .outerCircles {
      height: 100vh;
      width: 100%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      .outerCircle {
        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transform: rotate($i * 60deg) translate(0, -194px);
            animation: outerCircleAnimation#{$i} 12s infinite linear;
          }
        }

        @for $i from 1 through 6 {
          @keyframes outerCircleAnimation#{$i} {
            0% {
              transform: rotate($i * 60deg) translate(0, -194px);
            }

            50% {
              transform: rotate($i * 60deg + 180deg) translate(0, -194px);
            }

            100% {
              transform: rotate($i * 60deg + 360deg) translate(0, -194px);
            }
          }
        }
      }
    }
  }
}