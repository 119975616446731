@import '../App.scss';

.clock {
  height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .outer {
    width: 400px;
    height: 400px;
    border-radius: 50px;
    background-color: rgb(234, 239, 244);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 30px 30px 30px 10px rgba(0, 0, 0, 0.15),
      -15px -15px 35px 10px rgba(255, 255, 255, 0.25),
      inset 15px 15px 6px rgba(255, 255, 255, 1),
      inset -15px -15px 10px rgb(0, 0, 0, 0.15);

    .clock {
      width: 320px;
      height: 320px;
      border-radius: 50%;
      background-color: $primaryDark;
      position: relative;

      .numbers {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .num {
          font-size: 30px;
          font-family: sans-serif;
          color: white;
          position: absolute;
          top: calc(50% - 15px);
          left: calc(50% - 15px);

          &.num3 {
            transform-origin: center center;
            transform: rotate(90deg) translateY(-130px);

            #num3 {
              transform: rotate(-90deg);
            }
          }

          &.num6 {
            transform-origin: center center;
            transform: rotate(180deg) translateY(-130px);

            #num6 {
              transform: rotate(-180deg);
            }
          }

          &.num9 {
            transform-origin: center center;
            transform: rotate(270deg) translateY(-130px);

            #num9 {
              transform: rotate(-270deg);
            }
          }

          &.num12 {
            transform-origin: center center;
            transform: rotate(360deg) translateY(-130px);

            #num12 {
              transform: rotate(-360deg);
            }
          }
        }
      }

      .center {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        z-index: 10;
      }

      .hand {
        position: absolute;
        bottom: 50%;
        transform-origin: bottom center;
      }

      .hour {
        width: 10px;
        height: 80px;
        border-radius: 5px;
        background-color: white;
        left: calc(50% - 5px);
      }

      .min {
        width: 6px;
        height: 100px;
        border-radius: 3px;
        background-color: white;
        left: calc(50% - 3px);
      }

      .sec {
        width: 2px;
        height: 120px;
        border-radius: 1px;
        background-color: red;
        left: calc(50% - 1px);
      }
    }
  }

}